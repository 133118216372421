import React from "react"
import styled from "astroturf"

export const BlockOfOpportunity = () => {
  return (
    <Container className={"container"}>
      <Block>
        <Title>UX-исследования</Title>
        <Line />
        <Description>Наши юзабилити-эксперты выразят сложный функционал в простом и понятном интерфейсе</Description>
      </Block>
      <Block>
        <Title>Выбор дизайна</Title>
        <Line />
        <Description>Создаем 2 варианта дизайна наиболее подходящих к предметной области и идее продукта</Description>
      </Block>
      <Block>
        <Title>A/B тестирование</Title>
        <Line />
        <Description>
          При необходимости организуем рабочие группы из целевой аудитории и проводим тестирование интерфейса
        </Description>
      </Block>
      <Block>
        <Title>Прототипирование</Title>
        <Line />
        <Description>
          На основе нарисованных экранов быстро собираем первый “живой” прототип для наглядной демонстрации идеи
        </Description>
      </Block>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 150px;
  grid-row-gap: 80px;

  padding-top: 80px;

  @media (max-width: 1199px) {
    grid-column-gap: 50px;
  }

  @media (max-width: 991px) {
    grid-row-gap: 40px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 420px) {
    padding-top: 40px;
  }
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  color: #121e30;

  @media (max-width: 991px) {
    font-size: 24px;
  }
`

const Line = styled.div`
  width: 146px;
  height: 2px;
  background: #dfe5ec;

  margin: 20px 0;

  @media (max-width: 991px) {
    margin: 7px 0 14px 0;
  }
`

const Description = styled.div`
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #121e30;

  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 22px;
  }
`
